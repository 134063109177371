import { JourneyPaymentType } from "../enums/journey-payment-type";
import { OwnerType } from "./owner-type";
import { Payment } from "./household";
import { PaymentTypes } from "../enums/payment-type";

export interface JourneyPayment extends Omit<Payment, "id"> {
    journeyID: string;
    journeyInstanceID: string;
    owner: OwnerType;
    created: Date;
    lastModified: Date;

    isDeleted: boolean;

    // Override the id to make it a string and required.
    paymentID: string;

    journeyPaymentType: JourneyPaymentType
}

export class JourneyPaymentContext {
    constructor(
        public readonly journeyPaymentType: JourneyPaymentType,
        public readonly owner: OwnerType,
        // Payment types that we do not want to appear in the UI.
        readonly excludedPaymentTypes: PaymentTypes[],
    ) { }


    static createContext(journeyPaymentType: JourneyPaymentType, owner: OwnerType, excludedPaymentTypes: PaymentTypes[]) {
        return new JourneyPaymentContext(journeyPaymentType, owner, excludedPaymentTypes)
    }

    isItemForContext(item: JourneyPayment) {
        return (item.owner === this.owner || item.owner === "joint")
            && (this.journeyPaymentType === item.journeyPaymentType)
            && (!this.excludedPaymentTypes.includes(item.typeId));
    }

    getName(): string {
        return this.journeyPaymentType === JourneyPaymentType.Income
            ? "Income"
            : "Expense"
    }

    isIncome(): boolean {
        return this.journeyPaymentType === JourneyPaymentType.Income;
    }
}