import { AosPaymentType } from '../entities/aos-payment-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AosMasterDataServiceService {

  constructor(private readonly http: HttpClient,
  ) { }

  private readonly baseUrl = "/api/aosData";

  GetPaymentTypeData(): Observable<AosPaymentType[]> {
    return this.http.get<AosPaymentType[]>(`${this.baseUrl}/getPaymentsData`)
  }
}