import { Injectable, signal } from '@angular/core';
import { JourneyPayment, JourneyPaymentContext } from '../entities/journey-payment';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { PdfSection, TableContent, TableRow } from '../entities/pdf-content';
import { getCurrency, mapJourneyIdToDtoId, zero } from '../shared/util';
import { AosMasterDataServiceService } from './aos-master-data-service.service';
import { AosPaymentType } from '../entities/aos-payment-type';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { JourneyPaymentType } from '../enums/journey-payment-type';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import { OwnerType } from '../entities/owner-type';
import { Payment } from '../entities/household';
import { PaymentTypes } from '../enums/payment-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';

@Injectable({
    providedIn: 'root'
})
export class JourneyPaymentService {

    constructor(
        private http: HttpClient,
        private journeyService: JourneyService,
        private householdService: HouseholdService,
        private readonly journeyClientService: JourneyClientService,
        private readonly aosMasterDataService: AosMasterDataServiceService
    ) { }

    private readonly apiPath = "/api/journeyPayments";
    private _dataInitialised = false;
    readonly excludedPaymentTypes = [PaymentTypes.CalculatedTax];

    get dataInitialised() {
        return this._dataInitialised;
    }

    items = signal<JourneyPayment[]>([]);

    async delete(item: JourneyPayment) {
        item.isDeleted = true;
        await this.saveToApi([item]);
        this.items.update(current => current.filter(x => x.paymentID !== item.paymentID));
    }

    async update(item: JourneyPayment) {
        await this.saveToApi([item]);
        this.items.update(current => [...current.filter(x => x.paymentID !== item.paymentID), item]);
    }

    async add(item: JourneyPayment) {
        await this.saveToApi([item]);
        this.items.update(current => [...current, item]);
    }

    async refresh() {
        const household = this.householdService.household;
        if (!household) {
            throw new Error("The household object has not been initialised.");
        }

        const items: JourneyPayment[] = [];
        const now = new Date();

        items.push(...household.incomes.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Income, "joint", now)));
        items.push(...household.client.incomes.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Income, "primary", now)));
        items.push(...household.spouse?.incomes.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Income, "spouse", now)) ?? []);

        items.push(...household.expenses.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Expense, "joint", now)));
        items.push(...household.client.expenses.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Expense, "primary", now)));
        items.push(...household.spouse?.expenses.map(x => this.mapHouseholdToJourney(x, JourneyPaymentType.Expense, "spouse", now)) ?? []);

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    refreshFromApi() {
        this.ensureDataInitialised();
        return this.getFromApi().pipe(
            tap((items) => { this.items.set(items) })
        );
    }

    ensureDataInitialised() {
        if (!this.dataInitialised) {
            throw new Error("The data has not been initialised.");
        }
    }

    private mapHouseholdToJourney(dto: Payment, paymentType: JourneyPaymentType, owner: OwnerType, now: Date): JourneyPayment {
        if (!dto.id) {
            throw new Error("A Payment object cannot have null value for its id column.");
        }

        return {
            value: dto.value,
            frequencyId: dto.frequencyId,
            typeId: dto.typeId,

            journeyPaymentType: paymentType,
            journeyID: this.journeyService.getNonNullableJourney().journeyID,
            journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
            owner,
            paymentID: dto.id.toString(),
            created: now,
            lastModified: now,
            isDeleted: false
        };
    }

    private getFromApi(previousJourneyInstanceID?: string): Observable<JourneyPayment[]> {
        const journeyId = this.journeyService.getNonNullableJourney().journeyID;
        const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
        return this.http.get<JourneyPayment[]>(`${this.apiPath}/${journeyId}/${journeyInstanceId}/latest`);
    }

    mapJourneyToHouseholdForJourneyPaymentType(journeyPaymentType: JourneyPaymentType): Record<OwnerType, Payment[]> {
        this.ensureDataInitialised();
        return JourneyPaymentService.mapJourneyToHousehold(this.items().filter(x => x.journeyPaymentType === journeyPaymentType));
    }

    async getPdfSections(): Promise<PdfSection[]> {
        const [, aosPaymentTypes] = await Promise.all([
            firstValueFrom(this.refreshFromApi()),
            firstValueFrom(this.aosMasterDataService.GetPaymentTypeData())
        ]);

        const pdfSections: PdfSection[] = [];

        const primaryIncomes = this.items().filter(x => JourneyPaymentContext.createContext(JourneyPaymentType.Income, "primary", this.excludedPaymentTypes).isItemForContext(x));
        const primaryExpenses = this.items().filter(x => JourneyPaymentContext.createContext(JourneyPaymentType.Expense, "primary", this.excludedPaymentTypes).isItemForContext(x));

        if (primaryIncomes[0]) {
            pdfSections.push(this.getPaymentPDFSection(primaryIncomes, `${this.journeyClientService.getOwnerDropdownName("primary")} income`, aosPaymentTypes));
        }

        if (primaryExpenses[0]) {
            pdfSections.push(this.getPaymentPDFSection(primaryExpenses, `${this.journeyClientService.getOwnerDropdownName("primary")} expense`, aosPaymentTypes));
        }

        if (this.journeyClientService.spouseJourneyClient()) {
            const spouseIncomes = this.items().filter(x => JourneyPaymentContext.createContext(JourneyPaymentType.Income, "spouse", this.excludedPaymentTypes).isItemForContext(x));
            const spouseExpenses = this.items().filter(x => JourneyPaymentContext.createContext(JourneyPaymentType.Expense, "spouse", this.excludedPaymentTypes).isItemForContext(x));

            if (spouseIncomes[0]) {
                pdfSections.push(this.getPaymentPDFSection(spouseIncomes, `${this.journeyClientService.getOwnerDropdownName("spouse")} income`, aosPaymentTypes));
            }

            if (spouseExpenses[0]) {
                pdfSections.push(this.getPaymentPDFSection(spouseExpenses, `${this.journeyClientService.getOwnerDropdownName("spouse")} expense`, aosPaymentTypes));
            }
        }

        return pdfSections;
    }

    async loadJourneyPayments(previousJourneyInstanceID: string): Promise<void> {
        const items = await firstValueFrom(this.getFromApi(previousJourneyInstanceID));

        const now = new Date();
        for (const item of items) {
            item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
            item.created = now;
            item.lastModified = now;
        }

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    static getAnnualPaymentAmount(journeyPayment: JourneyPayment): number {
        if (!journeyPayment.frequencyId || !journeyPayment.value) return zero;

        return journeyPayment.value * journeyPayment.frequencyId;
    }

    private getPaymentPDFSection(items: JourneyPayment[], name: string, aosPaymentTypes: AosPaymentType[]): PdfSection {
        const pdfSection: PdfSection = {
            breakLine: false,
            pdfSectionType: PdfSectionTypes.Table,
            title: name,
            content: {
                tableHeaders: [
                    {
                        name: "Type",
                        width: "25%"
                    },
                    {
                        name: "Value",
                        width: "25%"
                    },
                    {
                        name: "Frequency",
                        width: "25%"
                    },
                    {
                        name: "Owner",
                        width: "25%"
                    }
                ],
                tableRows: []
            }
        };

        for (const item of items) {
            const paymentTypeName = aosPaymentTypes.find(x => x.id === item.typeId)?.name ?? String(item.typeId);
            (pdfSection.content as TableContent).tableRows.push(this.getJourneyPaymentTableRow(item, paymentTypeName));
        }

        return pdfSection;
    }

    private getJourneyPaymentTableRow(payment: JourneyPayment, paymentTypeName: string): TableRow {
        return {
            columns: [
                {
                    content: paymentTypeName
                },
                {
                    content: getCurrency(payment.value)
                },
                {
                    content: MasterDataService.getNameForId(payment.frequencyId, MasterDataService.getPaymentFrequencyTypes())
                },
                {
                    content: this.journeyClientService.getOwnerDropdownName(payment.owner)
                }
            ]
        }
    }

    private static mapJourneyToHousehold(items: JourneyPayment[]): Record<OwnerType, Payment[]> {
        return {
            primary: JourneyPaymentService.mapJourneyToHouseholdForOwner(items, "primary"),
            spouse: JourneyPaymentService.mapJourneyToHouseholdForOwner(items, "spouse"),
            joint: JourneyPaymentService.mapJourneyToHouseholdForOwner(items, "joint"),
        }
    }

    private static mapJourneyToHouseholdForOwner(items: JourneyPayment[], owner: OwnerType): Payment[] {
        return items
            .filter(x => x.owner === owner)
            .map(x => ({
                id: mapJourneyIdToDtoId(x.paymentID),
                typeId: x.typeId,
                frequencyId: x.frequencyId,
                value: x.value,
            }));
    }

    private async saveToApi(items: JourneyPayment[]): Promise<void> {
        await firstValueFrom(this.http.post(`${this.apiPath}`, items));
    }
}